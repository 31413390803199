import { Vue } from 'vue-property-decorator'
import {
    TASKLIST_ACTION_TYPES,
    TASKLIST_STATUSES,
    TASKLIST_COMMUNICATIONS_TYPES,
} from '../../../../vars/GCB2'
import * as t from '@/store/chisai/GCB2/actionTypes'
import { Project } from '../../../../types/main'
import { CONNECTOR_TYPES, TELEPHONY_CONNECTOR_TYPES } from '../../../../vars/general'
import { useGcb2Store } from '../../../../store/stores/gcb2/gcb2'
import _ from 'lodash'
import websocket from '../../../../websockets'
import { makeCall as makeCallMG } from '../../../../api/connectors/telephonyMegaphone'
import { makeCall as makeCallPZ } from '../../../../api/connectors/telephonyProstyiZvonki'
import { makeCall as makeCallSipuni } from '../../../../api/connectors/sipuni'
import { makeCall as makeCallMango } from '../../../../api/connectors/telephonyMango'
import { makeCall as makeCallBeeline } from '../../../../api/connectors/telephonyBeeline'
import { checkRole } from '../../../../helpers/roles'
import { ManualCommunication } from '../../../../types/GCB2'
import { setSnackBarNotification } from '../../../../api/notification'
import { ImportantError } from '../../../../helpers/Error'
import api from '@root/src/api/api'
import taskListActions from '@/components/chisai/GCB2/taskList/actions'

import { callNow as makeCallPbx } from '@api/connectors/pbxOnline'

import makeCallWrapper, { Telephonyes } from '@root/src/api/connectors/makeCallWrapper'
import { setChangedActivationNotification } from '@api/chisai/GCB2'

export interface TasklistContactActionParams {
    vueInstanse: Vue
    item: any
    textMessage?: string
    comment?: string
    communicationTags?: string[]
    commType: string
}
export interface TasklistDontRemindActionParams {
    vueInstanse: Vue
    item: any
    comment: string
    communicationTags: string[]
}
export interface TasklistChangeCommentActionParams {
    vueInstanse: Vue
    item: any
    comment: string
    communicationTags: string[]
}
export interface TasklistChangeExpectedDateActionParams {
    vueInstanse: Vue
    item: any
    comment: string
    communicationTags: string[]
    postponedTo: string
}

const beforeEach = (vueInstanse: Vue, item: any) => {
    vueInstanse.$store.commit(`GCB2/${t.ADD_LOADING_COMMUNNICATION_IDS}`, [item.communicationId])
}
const afterEach = (vueInstanse: Vue, item: any) => {
    vueInstanse.$store.commit(`GCB2/${t.REMOVE_LOADING_COMMUNNICATION_IDS}`, [item.communicationId])
}
const batchBeforeEach = (vueInstanse: Vue, actionData: any) => {
    vueInstanse.$store.commit(
        `GCB2/${t.ADD_LOADING_COMMUNNICATION_IDS}`,
        _.map(actionData, 'communicationId')
    )
}
const batchAfterEach = (vueInstanse: Vue, actionData: any) => {
    vueInstanse.$store.commit(
        `GCB2/${t.REMOVE_LOADING_COMMUNNICATION_IDS}`,
        _.map(actionData, 'communicationId')
    )
}
const onError = (vueInstanse: Vue, item: ManualCommunication | null) => {
    //const gcb2Store = useGcb2Store()
    //gcb2Store.setTasklistErrorModalIsActive(true)
    if (item) {
        vueInstanse.$store.commit(`GCB2/${t.TASKLIST_BATCH_CHANGE_ITEM}`, [
            {
                communicationId: item.communicationId,
                fields: {
                    color: null,
                },
            },
        ])
    }
}
const onErrorBatch = (vueInstanse: Vue, items: any[] | null) => {
    //const gcb2Store = useGcb2Store()
    //gcb2Store.setTasklistErrorModalIsActive(true)
    if (items) {
        vueInstanse.$store.commit(
            `GCB2/${t.TASKLIST_BATCH_CHANGE_ITEM}`,
            items.map(item => ({
                communicationId: item.communicationId,
                fields: {
                    color: null,
                },
            }))
        )
    }
}

export default {
    isActionAvailable: ({ item, actionType, project, userId }: any) => {
        switch (actionType) {
            case TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                    TASKLIST_STATUSES.COMMUNICATION_ERROR,
                ].includes(item.status)
            }

            case TASKLIST_ACTION_TYPES.CONTACT: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                    TASKLIST_STATUSES.COMMUNICATION_ERROR,
                ].includes(item.status)
            }

            case TASKLIST_ACTION_TYPES.DONT_CONTACT: {
                return (
                    [TASKLIST_STATUSES.CONTACTED_AND_DONT_COME].includes(item.status) &&
                    checkRole(project.id, 'gcb2:tasklist:action:dontContact:view')
                )
            }
            case TASKLIST_ACTION_TYPES.DONT_REMIND: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.CONTACTED_AND_COME,
                    TASKLIST_STATUSES.CONTACTED_AND_DONT_COME,
                    TASKLIST_STATUSES.COMMUNICATION_ERROR,
                ].includes(item.status)
            }
            case TASKLIST_ACTION_TYPES.REMIND: {
                return [TASKLIST_STATUSES.DONT_REMIND, TASKLIST_STATUSES.DONT_REMIND_SERVICE].includes(
                    item.status
                )
            }
            case TASKLIST_ACTION_TYPES.CHANGE_COMMENT: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                    TASKLIST_STATUSES.CONTACTED_AND_DONT_COME,
                    TASKLIST_STATUSES.CONTACTED_AND_COME,
                    TASKLIST_STATUSES.DONT_REMIND,
                    TASKLIST_STATUSES.COMMUNICATION_ERROR,
                ].includes(item.status)
            }
            case TASKLIST_ACTION_TYPES.CHANGE_MODA: {
                return (
                    [
                        TASKLIST_STATUSES.NEED_TO_CONTACT,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                        TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                        TASKLIST_STATUSES.CONTACTED_AND_DONT_COME,
                        TASKLIST_STATUSES.CONTACTED_AND_COME,
                        TASKLIST_STATUSES.COMMUNICATION_ERROR,
                    ].includes(item.status) &&
                    (checkRole(project.id, 'gcb2:tasklist:moda:general:view') ||
                        checkRole(project.id, 'gcb2:tasklist:moda:personal:view'))
                )
            }

            case TASKLIST_ACTION_TYPES.SEND_MESSAGE: {
                const hasYClientsConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.yClients
                )
                const hasWhatsappConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.whatsapp
                )
                const hasWhatsappBasisConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.whatsappBasis
                )
                const hasAltegioConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.altegio
                )
                const hasYCHrefInfo = item?.hrefInfo?.find(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.yClients
                )
                const hasTelHrefInfo = item?.hrefInfo?.find((el: any) => el.connectorType === 'tel')
                return (
                    [
                        TASKLIST_STATUSES.NEED_TO_CONTACT,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    ].includes(item.status) &&
                    ((hasYClientsConnector && hasYCHrefInfo) ||
                        (hasWhatsappConnector && hasTelHrefInfo) ||
                        (hasAltegioConnector && hasTelHrefInfo) ||
                        (hasWhatsappBasisConnector && hasTelHrefInfo))
                )
            }

            case TASKLIST_ACTION_TYPES.MAKE_CALL: {
                const hasTelHrefInfo = item?.hrefInfo?.find((el: any) => el.connectorType === 'tel')
                const telephonyConnector = project.connectors.find((el: any) =>
                    TELEPHONY_CONNECTOR_TYPES.includes(el.connectorType)
                )
                const hasUserMapping = telephonyConnector?.usersMapping?.[userId]
                return (
                    telephonyConnector &&
                    hasUserMapping &&
                    hasTelHrefInfo &&
                    item.status !== TASKLIST_STATUSES.IN_PROGRESS
                )
            }
            case TASKLIST_ACTION_TYPES.IN_PROGRESS_AGAIN: {
                return [TASKLIST_STATUSES.COMMUNICATION_ERROR].includes(item.status)
            }
            default:
                return false
        }
    },
    contact: async ({
        vueInstanse,
        item,
        textMessage,
        comment,
        communicationTags,
        commType,
    }: TasklistContactActionParams) => {
        const gcb2Store = useGcb2Store()
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })
            const phone = item.hrefInfo.find(el => el.connectorType === 'tel')
            let isCall = localStorage.getItem(`make_call_${phone.phone.replace(/\D/g, '')}`) || false
            const user = _this.$store.getters.myUser
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CONTACT,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    commType: isCall ? TASKLIST_COMMUNICATIONS_TYPES.PHONE : commType,
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    clientId: item.clientId,
                    userId: user.sub,
                    userName: user.name,
                    email: user.email,
                    textMessage: textMessage,
                    comment,
                    communicationTags,
                },
            })
            localStorage.removeItem(`make_call_${phone.phone.replace(/\D/g, '')}`)
            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CONTACT', item },
                originalError: err,
            })
        }
    },
    inProgress: async ({
        vueInstanse,
        item,
        textMessage,
        comment,
        communicationTags,
        commType,
    }: TasklistContactActionParams) => {
        const gcb2Store = useGcb2Store()
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })

            const user = _this.$store.getters.myUser
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.IN_PROGRESS,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    commType,
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    clientId: item.clientId,
                    userId: user.sub,
                    userName: user.name,
                    email: user.email,
                    textMessage: textMessage,
                    comment,
                    communicationTags,
                },
            })

            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'IN_PROGRESS', item },
                originalError: err,
            })
        }
    },
    communicationError: async ({
        vueInstanse,
        item,
        textMessage,
        comment,
        communicationTags,
        commType,
    }: TasklistContactActionParams) => {
        const gcb2Store = useGcb2Store()
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })

            const user = _this.$store.getters.myUser
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.COMMUNICATION_ERROR,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    commType,
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    clientId: item.clientId,
                    userId: user.sub,
                    userName: user.name,
                    email: user.email,
                    textMessage: textMessage,
                    comment,
                    communicationTags,
                },
            })

            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'COMMUNICATION_ERROR', item },
                originalError: err,
            })
        }
    },
    dontContact: async (vueInstanse: Vue, item: any) => {
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.DONT_CONTACT,
                communicationId: item.communicationId,
                statusText: item.status,
                messageError: false,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                },
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'DONT_CONTACT', item },
                originalError: err,
            })
        }
    },
    dontRemind: async ({ vueInstanse, item, comment, communicationTags }: TasklistDontRemindActionParams) => {
        const gcb2Store = useGcb2Store()
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.DONT_REMIND,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    communicationId: item.communicationId,
                    projectId: item.projectId,
                    clientId: item.clientId,
                    comment: comment,
                    userId: user.sub,
                    communicationTags,
                },
            })
            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'DONT_REMIND', item },
                originalError: err,
            })
        }
    },
    dontRemindService: async (vueInstanse: Vue, projectId: string, diff: any) => {
        const gcb2Store = useGcb2Store()
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                actionType: TASKLIST_ACTION_TYPES.DONT_REMIND_SERVICE,
                communicationId: null,
                socketId,
                actionData: {
                    projectId: projectId,
                    diff,
                    userId: user.sub,
                },
            })
        } catch (err) {
            onError(vueInstanse, null)
            throw new ImportantError('tasklist action', {
                payload: { action: 'DONT_REMIND_SERVICE' },
                originalError: err,
            })
        }
    },
    remind: async (vueInstanse: Vue, item: any) => {
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.REMIND,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    communicationId: item.communicationId,
                    projectId: item.projectId,
                    clientId: item.clientId,
                    serviceId: item.serviceId,
                },
            })
            await setChangedActivationNotification({
                projectId: item.projectId,
                active: true,
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'REMIND', item },
                originalError: err,
            })
        }
    },
    changeComment: async ({
        vueInstanse,
        item,
        comment,
        communicationTags,
    }: TasklistChangeCommentActionParams) => {
        try {
            const socketId = websocket.socket.id
            beforeEach(vueInstanse, item)
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CHANGE_COMMENT,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    comment: comment,
                    communicationTags,
                    userId: user.sub,
                },
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_COMMENT', item },
                originalError: err,
            })
        }
    },
    changeExpectedDate: async ({
        vueInstanse,
        item,
        comment,
        postponedTo,
        communicationTags,
    }: TasklistChangeExpectedDateActionParams) => {
        try {
            const socketId = websocket.socket.id
            const gcb2Store = useGcb2Store()
            beforeEach(vueInstanse, item)
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    postponedTo,
                    userId: user.sub,
                    userName: user.name,
                    email: user.email,
                    comment: comment,
                    communicationTags,
                    clientId: item.clientId,
                },
            })
            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_EXPECTED_DATE', item },
                originalError: err,
            })
        }
    },
    changeTextMessage: async (vueInstanse: Vue, item: any, textMessage: string) => {
        try {
            const socketId = websocket.socket.id
            beforeEach(vueInstanse, item)
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CHANGE_TEXT_MESSAGE,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    textMessage: textMessage,
                },
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_TEXT_MESSSAGE', item },
                originalError: err,
            })
        }
    },
    batchChangeTextMessage: async (
        vueInstanse: Vue,
        projectId: string,
        data: {
            projectId: string
            communicationId: string
            textMessage: string
        }[]
    ) => {
        try {
            const socketId = websocket.socket.id
            batchBeforeEach(vueInstanse, data)
            const _this = vueInstanse
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                socketId,
                actionType: TASKLIST_ACTION_TYPES.BATCH_CHANGE_TEXT_MESSAGE,
                actionData: data,
            })
            batchAfterEach(vueInstanse, data)
        } catch (err) {
            batchAfterEach(vueInstanse, data)
            onErrorBatch(vueInstanse, data)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_TEXT_MESSSAGE' },
                originalError: err,
            })
        }
    },
    batchInProgress: async (
        vueInstanse: Vue,
        projectId: string,
        data: {
            projectId: string
            communicationId: string
            clientId: string
            userId: string
            userName: string
            email: string
            textMessage: string
            commType: string
        }[]
    ) => {
        try {
            const socketId = websocket.socket.id
            batchBeforeEach(vueInstanse, data)
            const _this = vueInstanse
            const res = await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                socketId,
                actionType: TASKLIST_ACTION_TYPES.IN_PROGRESS,
                actionData: data,
            })
            batchAfterEach(vueInstanse, data)
        } catch (err) {
            batchAfterEach(vueInstanse, data)
            onErrorBatch(vueInstanse, data)
            throw new ImportantError('tasklist action', {
                payload: { action: 'IN_PROGRESS', dataLength: data.length },
                originalError: err,
            })
        }
    },
    batchCommunicationError: async (
        vueInstanse: Vue,
        projectId: string,
        data: {
            projectId: string
            communicationId: string
            clientId: string
            userId: string
            userName: string
            email: string
            textMessage: string
            commType: string
        }[]
    ) => {
        try {
            const socketId = websocket.socket.id
            batchBeforeEach(vueInstanse, data)
            const _this = vueInstanse
            const res = await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                socketId,
                actionType: TASKLIST_ACTION_TYPES.COMMUNICATION_ERROR,
                actionData: data,
            })
            batchAfterEach(vueInstanse, data)
        } catch (err) {
            batchAfterEach(vueInstanse, data)
            onErrorBatch(vueInstanse, data)
            throw new ImportantError('tasklist action', {
                payload: { action: 'COMMUNICATION_ERROR', dataLength: data.length },
                originalError: err,
            })
        }
    },
    websocketAction: async (vueInstanse: Vue, payload: any) => {
        const [beforeEachFunc, afterEachFunc] = [
            TASKLIST_ACTION_TYPES.BATCH_CHANGE_TEXT_MESSAGE,
            TASKLIST_ACTION_TYPES.BATCH_CONTACT,
        ].includes(payload.actionType)
            ? [beforeEach, afterEach]
            : [batchBeforeEach, batchAfterEach]
        beforeEachFunc(vueInstanse, payload.actionData)
        const _this = vueInstanse
        await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
            projectId: payload.projectId,
            actionType: payload.actionType,
            actionData: payload.actionData,
            communicationId: payload.communicationId,
            responseData: payload.responseData,
        })
        afterEachFunc(vueInstanse, payload.actionData)

        if (payload.responseData[0].fields.messageError
        ) {
            _this.$store.commit(`GCB2/${t.APPEND_MESSAGE_ERRORS}`, {
                message: {
                    communicationId: payload.communicationId
                },
                reason: payload.responseData[0].errorMessage
            })
        }
    },
    makeCall: async ({ vueInstanse, item, project }: { vueInstanse: Vue; item: any; project: Project }) => {
        try {
            let hasError = false
            vueInstanse.$store.commit(`GCB2/${t.ADD_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                item.communicationId,
            ])
            const phone = item.hrefInfo
                .find((el: any) => el.connectorType === 'tel')
                ?.phone.replace(/\D/g, '')
            const telephonyConnector = project.connectors.find((el: any) =>
                TELEPHONY_CONNECTOR_TYPES.includes(el.connectorType)
            )

            let makeCall
            const isPbxOnline = telephonyConnector?.connectorType === CONNECTOR_TYPES.pbxOnline
            const isMango = telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyMango
            const isBeeline = telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyBeeline

            if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyMegaphone) {
                makeCall = makeCallMG
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyProstyiZvonki) {
                makeCall = makeCallPZ
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.sipuni) {
                makeCall = makeCallSipuni
            } else if (isPbxOnline) {
                makeCall = makeCallPbx
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyMango) {
                makeCall = makeCallMango
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyBeeline) {
                makeCall = makeCallBeeline
            }

            if (!makeCall) {
                throw new ImportantError('Telephony makeCall method not found')
            }

            if (isPbxOnline) {
                const userId = vueInstanse.$store.getters.myUser.sub
                const { data, error } = await makeCall({
                    connectorId: telephonyConnector?.connectorId,
                    from: telephonyConnector?.usersMapping?.[userId],
                    to: phone,
                    domain: telephonyConnector?.domain,
                })
                vueInstanse.$store.commit(`GCB2/${t.REMOVE_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                    item.communicationId,
                ])
                if (error) {
                    hasError = true
                    if (error.comment === 'There is no registered user and no push token') {
                        vueInstanse.$store.dispatch(
                            'callNotify',
                            'Ошибка. Ваша телефония не работает. Включите ваше устройство/приложение телефонии для звонка.'
                        )
                    } else if (error.comment === 'NO_ANSWER') {
                        vueInstanse.$store.dispatch('callNotify', 'Ошибка. Оператор не отвечает.')
                    } else {
                        vueInstanse.$store.dispatch('callNotify', 'Ошибка: ' + error.comment)
                    }
                }
            } else if (isMango) {
                const userId = vueInstanse.$store.getters.myUser.sub
                let users = await api.connectors.telephonyMango.getUsers(telephonyConnector.connectorId)
                let mangoUsers = users.data.mangoUsers
                let mappedId = telephonyConnector.usersMapping[userId]
                let mappedUser = mangoUsers.find(user => user.telephony.numbers[0]?.number === mappedId)
                let extension = mappedUser.telephony.extension

                const { data, error } = await makeCall({
                    connectorId: telephonyConnector!.connectorId,
                    from: telephonyConnector?.usersMapping?.[userId],
                    toNumber: phone,
                    extension,
                })
                vueInstanse.$store.commit(`GCB2/${t.REMOVE_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                    item.communicationId,
                ])
                if (error) {
                    hasError = true
                    onError(vueInstanse, null)
                }
            } else if (isBeeline) {
                const userId = vueInstanse.$store.getters.myUser.sub
                let users = await api.connectors.telephonyBeeline.getUsers(telephonyConnector.connectorId)
                let beelineUsers = users.data.beelineUsers
                let mappedPhone = telephonyConnector.usersMapping[userId]
                let userIdBeeline = beelineUsers.find(user => user.phone === mappedPhone).phone

                const { data, error } = await makeCall({
                    connectorId: telephonyConnector!.connectorId,
                    phone,
                    userId: userIdBeeline,
                })
                vueInstanse.$store.commit(`GCB2/${t.REMOVE_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                    item.communicationId,
                ])
                if (error) {
                    hasError = true
                    onError(vueInstanse, null)
                }
            } else {
                const { data, error } = await makeCall({
                    connectorId: telephonyConnector!.connectorId,
                    phone,
                    communicationId: item.communicationId,
                })
                vueInstanse.$store.commit(`GCB2/${t.REMOVE_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                    item.communicationId,
                ])
                if (error) {
                    hasError = true
                    onError(vueInstanse, null)
                }
            }
            if (!hasError) {
                localStorage.setItem(`make_call_${phone}`, phone)
            }
        } catch (err) {
            onError(vueInstanse, null)
            throw new ImportantError('tasklist action', {
                payload: { action: 'MAKE_CALL', item },
                originalError: err,
            })
        }
    },
}
